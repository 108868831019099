/* eslint-disable max-len */
import 'src/pages/shopping/index.scss';
import { BILLING_FREQUENCY, FILTERED_PLANS, PAGES } from 'src/constants';
import React, { useContext, useEffect } from 'react';
import { useApi, useApiState, useCart, useConfig, useFilters, useFormData, useModal } from 'src/hooks';
import { EnrollmentContext } from 'src/components/layout/context-provider';

import Card from 'src/components/molecules/card';
import Link from 'src/components/atoms/link';
import SEO from 'src/components/atoms/seo';
import SearchModal from 'src/components/modals/search';
import Separator from 'src/components/atoms/separator';
import { navigate } from 'gatsby';

import type { Plan } from 'src/helpers/transformers/api/_types';
import { pushZipCodeEvent } from 'plugins/custom-plugin-adobe-launch';

export const DATA_LAYER_NAME = 'adobeDataLayer';

const { MONTHLY } = BILLING_FREQUENCY;
const { HOME, SHOPPING, CHECK_OUT } = PAGES;

const push = (event: any) => (window as any)?.[DATA_LAYER_NAME]?.push?.(event);
const getBrand = () => location.hostname?.split('.')?.reverse()?.[1] ?? '';

const PlanCard = ({ cart, enableTheme, plan, updateCart }: any) => (
  <Card {...(cart[plan.id] ?? plan)} onQuantityChange={updateCart} enableTheme={enableTheme} />
);

const SelectPlans = ({ shoppingPageTitle, showSearchModal, zipcode }: any) => {

  const { overrideStyles } = useConfig();

  return (
    <>
      <div className='shopping--select-plans'>
        <div className='shopping--select-plans--title'>{shoppingPageTitle}</div>
        <div className='shopping--select-plans--content'>
          <label className='shopping--select-plans--content--label'>Get personalized plans</label>
          <span className='shopping--select-plans--content--zipcode'>({zipcode}) </span>
          <Link className='shopping--select-plans--content--edit' hyperLinkClass={overrideStyles?.hyperLinkClass} label='Edit' onClick={showSearchModal} />
        </div>
      </div>
    </>
  );
};

const FeaturedPlans = ({ cart, plans, updateCart }: any) => (
  <div className='shopping--featured-plans'>
    <div className='shopping--featured-plans--title'>Featured plans</div>
    <div className='shopping--featured-plans--content'>
      {plans.map((plan: Plan) => (
        <PlanCard key={plan.id} {...{ cart, enableTheme: true, plan, updateCart }} />
      ))}
    </div>
  </div>
);

const AllPlans = ({ cart, categories, plans, updateCart }: any) => {
  const { Filters, activeFilters } = useFilters({ initialOptions: categories.initial, options: categories.all });
  const filteredPlans: any = [];
  sessionStorage.removeItem(FILTERED_PLANS.PLANS);
  let featuredPosition = 0;
  let filtersString = '';
  activeFilters.forEach(category => {
    filtersString = filtersString + category + '|';
    plans.forEach((plan: any) => {
      if (plan.category === category) {
        filteredPlans.push({ 'featuredPosition': ++featuredPosition, 'id': plan.id });
      }
    });
  });
  sessionStorage.setItem(FILTERED_PLANS.PLANS, JSON.stringify(filteredPlans));

  return (
    <div className='shopping--all-plans'>
      <div className='shopping--all-plans--title'>All plans</div>
      <Filters label='Category filters' />
      {activeFilters.map((category: string) => (
        <div key={category}>
          <div className='shopping--all-plans--header'>{category}</div>
          <div className='shopping--all-plans--content'>
            {plans?.filter((plan: Plan) => plan.category === category).map((plan: Plan) => (
              <PlanCard key={plan.id} {...{ cart, plan, updateCart }} />
            ))}
          </div>
        </div>
      ))}
    </div>
  );
};

export default () => {
  const { hideModal, showModal } = useModal();
  const { getPlans } = useApi();
  const { plans, categories, saveApiState, zipcode, displayAllPlans } = useApiState() as any;
  const { cart, clearCart, enableDiscountForm, setBillingFrequency, updateCart } = useCart() as any;
  const { clearFormData } = useFormData();
  const { shoppingPageTitle } = useConfig();
  let queryParams: any;
  if (typeof window !== 'undefined') {
    queryParams = new URLSearchParams(window.location.search);
  }

  const { setSharedState } = useContext(EnrollmentContext);
  const checkoutId = queryParams?.get('checkout_id');

  const featuredPlans = plans?.filter(({ isFeatured }: Plan) => isFeatured).sort((a: any, b: any) => a.featuredPosition - b.featuredPosition) || [];
  const hasFeaturedPlans = featuredPlans.length > 0;
  const showAllPlans: boolean = categories?.all?.length > 0 && displayAllPlans;

  const pushSetProduct = (product: any) => push({
    event: 'productGridView',
    global: {
      brand: getBrand(),
      eventAction: 'productGridView'
    },
    product: product
  });

  useEffect(() => {
    sessionStorage.removeItem('sps');
    !plans?.length && navigate(HOME);
    if (plans) {
      const product: any = [];
      let featuredPosition = 0;
      categories?.all.forEach((category: any) => {
        plans.forEach((plan: Plan) => {
          if (plan.category === category) {
            let planQuntity = 0;
            for (const key in cart) {
              if (key === plan.id) {
                planQuntity = cart[key].quantity;
              }
            }
            featuredPosition++;
            product.push({
              cancelReturnFee: 0.00,
              category: 'ANCILLARY SERVICE',
              discount: 0.00,
              flags: plan.promotion,
              gridPosition: featuredPosition,
              name: plan.name,
              planRateType: 'fixed',
              price: plan.price.monthlyAmount,
              priceTotal: Number((plan.price.monthlyAmount * planQuntity).toFixed(2)),
              quantity: planQuntity,
              sku: plan.id,
              term: 12,
              type: plan.category
            });
          }
        });
      });
      pushSetProduct(product);
    }

    if (checkoutId){
      sessionStorage.setItem('checkoutId', checkoutId);
      sessionStorage.setItem('isExpressCheckout', 'true');
      setSharedState({
        checkoutId,
        isExpressCheckout: true
      });
      navigate(CHECK_OUT);
    }

    setBillingFrequency(MONTHLY);
    saveApiState({ discount: null, totals: null });
    enableDiscountForm(false);

    return () => hideModal();
  }, [ checkoutId ]);

  const showSearchModal = () => {
    localStorage.setItem('ZipCodeModal', 'true');
    pushZipCodeEvent({
      event: 'linkClick',
      linkInfo:
      {
        linkAction: 'edit',
        linkName: 'Edit Zip Code',
        linkType: 'other',
        linkURL: SHOPPING
      }
    });
    const sps = sessionStorage.getItem('sps') ? sessionStorage.getItem('sps') : '';
    const onSubmit = (data: any) => getPlans({ sps, ...data }, () => {
      if (data?.zipcode) {
        pushZipCodeEvent({
          event: 'linkClick',
          linkInfo:
          {
            linkAction: 'enterZip',
            linkName: 'View Plans',
            linkType: 'other',
            linkURL: SHOPPING
          },
          userInfo:
          {
            zipCode: data.zipcode
          }
        });
      }
      localStorage.setItem('ZipCodeModal', 'true');
      clearCart();
      clearFormData();
      hideModal();
    });

    showModal({ innerContent: <SearchModal onSubmit={onSubmit} /> });
  };

  return (
    <div className='shopping'>
      <SelectPlans {...{ shoppingPageTitle, showSearchModal, zipcode }} />
      {hasFeaturedPlans && <>
        <Separator />
        <FeaturedPlans {...{ cart, plans: featuredPlans, updateCart }} />
      </>}
      {showAllPlans && <>
        <Separator />
        <AllPlans {...{ cart, categories, plans, updateCart }} />
      </>}
    </div>
  );
};

export const Head = () => <SEO pageTitle='Shopping' />;
